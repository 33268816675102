import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';

import { v4 as GenerateUUID } from 'uuid';

import 'bootstrap/dist/css/bootstrap.min.css';

const teaTypes = {
  "green": "green tea",
  "black": "black tea",
  "herbal": "herbal tea",
  "white": "white tea",
  "oolong": "oolong tea",
};

const teaUILetters = {
  "green": "G",
  "black": "B",
  "herbal": "H",
  "white": "W",
  "oolong": "O",
}

const teaProperties = {
  "caffeine": (tea) => tea.hasCaffeine,
  "format": (tea) => tea.form,
  "type": (tea) => tea.base,
};

const propertyComparators = {
  "=": (a, b) => {
    return a === b;
  },
  "!=": (a, b) => {
    return !(a === b);
  },
};

function TeaTitle(props) {
  const brand = props.tea.brand;
  const name = props.tea.name;

  let friendlyName = props.tea.friendlyName;
  if (!friendlyName) {
    friendlyName = "" + brand + " " + name;
  }

  return (
    <span>
      {friendlyName}
    </span>
  );
}

function TeaTypeIndicator(props) {
  const teaType = props.teaBase;
  const desc = teaTypes[teaType];
  const teaUIColour = "tea-" + teaType;
  const teaUILetter = teaUILetters[teaType];
  const teaClass = "large-circle " + teaUIColour;
  const title = desc[0].toUpperCase() + desc.slice(1);

  if (desc) {
    return (
      <span>
        <div className={teaClass} data-toggle="tooltip" title={title} >
          {teaUILetter}
        </div>

        <RestrictButtons
          baseline={teaType}
          property="type"
          entry={props.entry}
        />
      </span>
    );
  }
  return (
    <span>???</span>
  );
}

function TeaFormIndicator(props) {
  const teaForm = props.teaForm;
  return (
    <span>
        {teaForm}

        <RestrictButtons
          baseline={teaForm}
          property="format"
          entry={props.entry}
        />
    </span>
  );
}

function RestrictButtons(props) {
  const baseline = props.baseline;
  const propertyname = props.property;
  const ownprop = baseline;

  if (ownprop === undefined) {
    return <span/>;
  }

  const restrictTo = () => {
    props.entry.addFilter(propertyname, "=");
  };
  const exclude = () => {
    props.entry.addFilter(propertyname, "!=");
  };

  if (props.entry.hasFilterOn(propertyname)) {
    return <span/>;
  }

  return (
    <div>
      <span className="badge badge-pill badge-light"
            onClick={restrictTo}
      >
        +
      </span>
      <span className="badge badge-pill badge-light"
            onClick={exclude}
      >
        -
      </span>
    </div>
  )
}

function CaffeineIndicator(props) {
  const hasCaffeine = props.hasCaffeine;

  if (hasCaffeine === true) {
    return (
      <span>
        <div className="large-circle caffeinated-brown"
          data-toggle="tooltip"
          title="Contains caffeine"
        >
          C
        </div>

        <RestrictButtons
          entry={props.entry}
          baseline={hasCaffeine}
          property="caffeine"
        />
      </span>
    );
  }
  if (hasCaffeine === false) {
    return (
      <span>
        <div className="large-circle decaf-blue"
          data-toggle="tooltip"
          title="Caffeine-free"
        >
          N
        </div>

        <RestrictButtons
          baseline={hasCaffeine}
          property="caffeine"
          entry={props.entry}
        />
      </span>
    );
  }
  return (
    <span></span>
  );
}

function MinutesIndicator(props) {
  const mins = props.mins;

  return (
    <span>
      {mins}m
    </span>
  );
}

function SteepingTimeIndicator(props) {
  const steep = props.steepingTime;
  if (!steep) {
    return <span/>;
  }

  const maxMins = steep.maximumMins;
  const minMins = steep.minimumMins;
  const recMins = steep.recommendedMins;

  if (minMins && maxMins) {
    return (
      <span>
        <MinutesIndicator mins={minMins} />
        -
        <MinutesIndicator mins={maxMins} />
      </span>
    );
  }

  if (recMins) {
    return (
      <span>
        <MinutesIndicator mins={recMins} />
      </span>
    );
  }

  if (minMins) {
      return (
        <span>
          <MinutesIndicator mins={recMins} />+
        </span>
      );
  }

  if (maxMins) {
      return (
        <span>
          -<MinutesIndicator mins={maxMins} />
        </span>
      );
  }

  return (
    <span>
    </span>
  );
}

function SteepingSensitiveIndicator(props) {
  const sensitive = props.sensitive;
  if (sensitive) {
    return (
      <span className="sensitive-indicator">
        !
      </span>
    );
  }
  return <span />;
}

function SteepingIndicator(props) {
  const steep = props.steepingTime;
  if (!steep) {
    return <span/>;
  }

  return (
    <span>
      <SteepingTimeIndicator steepingTime={steep} />
      <SteepingSensitiveIndicator sensitive={steep.sensitive} />
    </span>
  );
}

class TeaFilter extends React.Component {
  onRemove() {
    this.props.filtermanager.removeFilterIf(filt => {
      return filt.props.uuid === this.props.uuid;
    });
  }
  
  render() {
    return (
      <button type="button" className="btn btn-primary"
        onClick={() => this.onRemove()}
      >
        {this.props.name}
      </button>
    );
  }
}

class TeaEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  setKindOfTea() {
    this.setState({
    });
  }

  hasFilterOn(prop) {
    return this.props.filtermanager.hasPropFilter(prop);
  }

  addFilter(propertyname, comparator) {
    const propertyvalue = teaProperties[propertyname](this.props.tea);
    this.props.filtermanager.addFilter(propertyname, comparator, propertyvalue);
  }

  onClick() {
    this.addFilter("Must be caffeinated", (tea) => {
      console.log("checking caffeination", tea, "is", tea.hasCaffeine);
      return tea.hasCaffeine;
    });
  }

  render() {
    return (
      <div className="tea-entry card" data-tea-id={this.props.tea.id}>
        <div className="card-body row no-gutters flex-nowrap">
          <div className="col-8">
            <h5 className="card-title">
              <TeaTitle tea={this.props.tea} />
            </h5>
          </div>

          <div className="col">
            <TeaTypeIndicator
              teaBase={this.props.tea.base}
              entry={this}
            />
          </div>

          <div className="col">
            <CaffeineIndicator
              hasCaffeine={this.props.tea.hasCaffeine}
              entry={this}
            />
          </div>

          <div className="col-2">
            <SteepingIndicator steepingTime={this.props.tea.steepingTime}
              entry={this}
            />
          </div>
        </div>
      </div>
    );
  }
}

class TeaMenu extends React.Component {
  render() {
    return (
      <div className="tea-menu">
        { this.props.teas.map(tea => (
          <TeaEntry
              key={ tea.id }
              tea={ tea }
              filtermanager={ this.props.filtermanager }
            />
        )) }
      </div>
    );
  }
}

class TeaFilters extends React.Component {
  render() {
    return (
      <div>
        {this.props.filters}
      </div>
    );
  }
}

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      error: null,
      filters: [],
    };
  }

  componentDidUpdate() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }


  componentDidMount() {
    axios.get(`/teas.json`)
      .then(res => {
        const teas = res.data;
        this.setState({
          allTeas: teas,
          teas: teas,
          loaded: true,
        })
      })
      .catch(err => {
        this.setState({
          error: "" + err,
          loaded: false,
        });
      });
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  removeFilterIf(pred) {
    this.setFilters(this.state.filters.filter(x => !pred(x)));
  }

  hasPropFilter(prop) {
    return this.state.filters.filter(x => x.props.propertyname === prop).length > 0;
  }

  rawAddFilter(filt) {
    this.setFilters(this.state.filters.concat(filt));
  }

  addFilter(propertyname, comparator, propertyval) {
    const s = JSON.stringify({
      "p": propertyname,
      "c": comparator,
      "v": propertyval,
    });
    const u = JSON.parse(s);
    const uuid = GenerateUUID();
    const name = "" + propertyname + comparator + propertyval;

    console.log(u);

    const getterFunc = teaProperties[propertyname];
    const comparatorFunc = propertyComparators[comparator];

    console.log(getterFunc);
    console.log(comparator, comparatorFunc);


    this.rawAddFilter(
      <TeaFilter
          key={uuid}
          serialized={s}
          uuid={uuid}
          filtermanager={this}
          allowTea={(tea) => comparatorFunc(getterFunc(tea), propertyval)}
          propertyname={propertyname}
          name={name}
      />
    );
  }

  filteredTeas(teas, filters) {
    return teas.filter((tea) => filters.every((filt) => {
      console.log(filt.props);
      return filt.props.allowTea(tea);
    }));
  }

  setFilters(filts) {
    this.setState({
      filters: filts,
      teas: this.filteredTeas(this.state.allTeas, filts),
    })
  }

  render() {
    if (this.state.error) {
      return (
        <div>
        <h1>Oops :(</h1>
        {this.state.error}
        </div>
      )
    }
    if (!this.state.loaded) {
      return (
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
    }
    return (
      <div>
        <TeaFilters filters={this.state.filters} />

        <TeaMenu teas={this.state.teas}
                 filtermanager={this}
        />
      </div>
    );
  }
}

ReactDOM.render(
  <App />,
  document.getElementById("root")
);
